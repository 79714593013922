<template>

<app-content :loading="is.loading">

	<app-content-head title="Settings">
		
		<template v-if="$authorised('con/badges/access', 'write')">

		<app-content-head-actions-item :disabled="!isDirty" icon="undo" text="Undo" v-on:click="onUndoClick" />
		<app-content-head-actions-item :loading="is.saving" :disabled="!isDirty || !isValid" icon="save" text="Save" v-on:click="onSaveClick" />

		</template>

	</app-content-head>

	<app-content-body>

		<app-content-box title="General" icon="settings" :readonly="$authorised('con/badges/access', 'read')">

			<app-input-toggle label="Allow Renaming" notes="Permit attendees to change the first and last name on their badge." v-model="model.badges.renaming.enabled" :validation="$v.model.badges.renaming.enabled" :options="permitOptions" />
			<app-input-toggle label="Allow Stacking" notes="Permit attendees to purchase multiple badge types and stack their benefits." v-model="model.badges.stacking.enabled" :validation="$v.model.badges.stacking.enabled" :options="permitOptions" />
			<app-input-toggle label="Show Numbers" notes="If hidden then attendees will instead see a randomised reference number. You can make badge numbers public anytime." v-model="model.badges.numbers.public" :validation="$v.model.badges.numbers.public" :options="numberOptions" />

		</app-content-box>

		<app-content-box title="Transfers" icon="transfer" :readonly="$authorised('con/badges/access', 'read')">

			<app-input-toggle label="Self Transfers" notes="Permit attendees to transfer their own badge to another person." v-model="model.badges.transfer.self" :validation="$v.model.badges.transfer.self" :options="permitOptions" />
			<app-input-date :time="true" label="Permitted From" placeholder="Enter date..." :toggle="model.badges.transfer.from.enabled" v-on:toggle="model.badges.transfer.from.enabled = !model.badges.transfer.from.enabled" v-model="model.badges.transfer.from.date" :validation="$v.model.badges.transfer.from.date" />
			<app-input-date :time="true" label="Permitted Until" placeholder="Enter date..." :toggle="model.badges.transfer.to.enabled" v-on:toggle="model.badges.transfer.to.enabled = !model.badges.transfer.to.enabled" v-model="model.badges.transfer.to.date" :validation="$v.model.badges.transfer.to.date" />
			<app-input-content :as-textbox="true" theme="information" label="Instructions" placeholder="Enter text..." v-model="model.badges.transfer.text.before" :validation="$v.model.badges.transfer.text.before" />

		</app-content-box>

	</app-content-body>

</app-content>

</template>

<script>

import mixForm from '@/mixin/form'

export default {

	mixins: [mixForm],

	data: function() {

		return {
			readonly: this.$authorised('con/badges/access', 'read'),
			settings: true,
			numberOptions: {
				0: 'Hide',
				1: 'Show'
			},
			permitOptions: {
				0: 'Disabled',
				1: 'Enabled'
			},
			model: {
				badges: {
					stacking: {
						enabled: false
					},
					renaming: {
						enabled: false
					},
					numbers: {
						public: false
					},
					transfer: {
						self: false,
						from: {
							enabled: false,
							date: 0
						},
						to: {
							enabled: false,
							date: 0
						},
						text: {
							before: ''
						}
					}
				}
			}
		}

	},

	validations: {
		model: {
			badges: {
				renaming: {},
				stacking: {},
				numbers: {},
				transfer: {
					to: {},
					from: {},
					text: {}
				}
			}
		}
	}

}

</script>

<style scoped>

</style>